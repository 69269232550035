<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      >
        <template #cell(type)="data">
          <span>{{ data.item.type == 'vehicle' ? 'Dokumen Kendaraan' : 'Dokumen SPB' }}</span>
        </template>

        <template #cell(is_required)="data">
          <center><span class="badge rounded-pill bg-info">{{ data.item.is_required == true ? 'Wajib' : 'Tidak Wajib' }}</span></center>
        </template>
      
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV2.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Dokumen',
      dataurl:'/file-type',
      baseroute:'dokumen',
      fields:[
        { key: 'name', label: 'Jenis Dokumen', sortable: true},
        { key: 'type', label: 'Tipe', sortable: true},
        { key: 'is_required', label: 'Status', sortable: true},
      ],
    }
  },
}
</script>